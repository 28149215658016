*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  color: #2C3659;
  background-color: #F6F7FA;
  margin: 0 auto;
}

body.drakmode {
  color: #fff;
  background-color: #000;
}

a {
  color: #2C3659;
}

body.drakmode a {
  color: #fff;
}

/* 
=============
Heading Tag
=============
*/
h1,
h2,
h3,
h4,
h5.h6,
p {
  margin: 0;
}



/* 
=============
Sidebar
=============
*/
.sidebarwrap {
  display: flex;
  width: 310px;
  height: 100vh;
  padding: 20px 24px;
  flex-direction: column;
  align-items: center;
  /* background-color: #fff; */
  background-image: url(./Images/sidebarbg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  box-shadow: 0px 0px 5px 0px #d5d3d3;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  transition: .6s;
}

.sidebarwrap::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000075;
  z-index: 1;
}

.sidebarwrap .top_area {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}

.sidebarwrap .sidebar_menu {
  position: relative;
  z-index: 2;
}

#backbtnsidebar {
  display: none;
  border: 1.5px solid #F5F5F5;
  background-color: transparent;
  text-decoration: none;
  outline: 0;
  cursor: pointer;
  box-shadow: none;
  height: 45px;
  width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  margin-right: 10px;
  color: #fff;
}

.logo_area {
  width: 100%;
  max-width: 262px;
  min-width: 262px;
  height: 70px;
  border-radius: 10px;
  /* border: 1.5px solid #F5F5F5; */
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}


.logo_area .dropdown {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.logo_area .logo {
  width: 110px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.logo_area .logo img {
  width: 100%;
  height: 100%;
}

.logo_area .dropdown a {
  text-decoration: none;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.logo_area .dropdown .dropdown-item:hover {
  background-color: #e6e6e6;
}

.logo_area .dropdown .dropdown-menu a {
  font-size: 14px;
  font-weight: 500;
}

.logo_area .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
}

.sidebar_menu {
  width: 100%;
  max-height: 575px;
  overflow-y: auto;
}

.sidebar_menu::-webkit-scrollbar {
  display: none;
}

.sidebar_menu .Menu_Nav {
  width: 100%;
  height: auto;
  padding: 49px 0;
}

.sidebar_menu .Menu_Nav .menuline {
  width: 100%;
  height: auto;
}

.sidebar_menu .nav a {
  list-style: none;
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  width: 100%;
  height: 40px;
  margin: 5px 0;
  display: flex;
  align-items: center;
  padding: 5px 14px;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .nav a:hover {
  color: rgb(236, 64, 122);
  font-weight: 600;
  font-size: 18px;
}

.sidebar_menu .nav a.active {
  color: rgb(236, 64, 122);
  font-weight: 600;
  font-size: 18px;
}

.sidebar_menu .nav a i {
  margin-right: 15px;
}

.sidebar_menu .Menu_Nav .Submenu {
  width: 100%;
  height: auto;
  padding-left: 30px;
  list-style: none;
}

.sidebar_menu .Menu_Nav .Submenu a {
  text-decoration: none;
  width: 100%;
  height: 25px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fff;
  opacity: 0.75;
  transition: 0.6s;
  cursor: pointer;
}

.sidebar_menu .Menu_Nav .Submenu a:hover {
  color: #f7e943;
  font-weight: 600;
  font-size: 14px;
}

.sidebar_menu .Menu_Nav .Submenu a.active {
  color: #f7e943;
  font-weight: 600;
  font-size: 14px;
}

/***/

/* 
=============
Header
=============
*/
.mainheader_sec {
  width: calc(100% - 310px);
  margin-left: auto;
  height: 80px;
  padding: 20px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 3px 0px 5px 0px #d5d3d3;
}

body.drakmode .mainheader_sec {
  background-color: #000;
  box-shadow: 1px 0px 5px 0px #F5F5F5;
}

.responsive_menu {
  display: none;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  border: none;
  background-color: transparent;
  font-size: 30px;
  cursor: pointer;
  padding: 0;
  margin: 0 20px 0 0;
  border: none;
}

body.drakmode .responsive_menu {
  color: #fff;
}

.Search_box {
  display: block;
}

.Search_box .form-inline {
  position: relative;
  width: 333px;
  height: 48px;
  border-radius: 10px;
}

.Search_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 10px 2px 40px;
  background: #EFF2F3;
}

.Search_box .Search_icon {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
}

.responsSearch_btn {
  background: #EFF2F3;
  text-decoration: none;
  border: none;
  outline: 0;
  box-shadow: none;
  border-radius: 4px;
  padding: 6px 12px;
  margin-right: auto;
  display: none;
}

.responsiveSearch_box .form-inline {
  position: fixed;
  width: 95%;
  height: 48px;
  border-radius: 10px;
  z-index: 9;
  top: 90px;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 0px 5px 0px #958f8f80;
  overflow: hidden;
}

.responsiveSearch_box .form-inline input {
  width: 100%;
  height: 100%;
  box-shadow: none;
  outline: 0;
  border: none;
  padding: 2px 50px 2px 10px;
  /* background: #EFF2F3; */
  background: #fff;
}

.responsiveSearch_box .Search_icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translate(-50%, -50%);
  z-index: 2;
  border: none;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  padding: 0;
  margin: 0;
  background: transparent;
}



.mainheader_sec .rightcontent {
  display: flex;
  align-items: center;
  justify-content: end;
  width: 50%;
}

.mainheader_sec .actionBtn_wrap {
  display: flex;
  align-items: center;
}

.Createpost_btn .btn {
  border-radius: 10px;
  background: #2C3659;
  display: flex;
  width: 151px;
  height: 46px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Createpost_btn .btn i {
  margin-right: 10px;
}

.notification_btn {
  position: relative;
}

.notification_btn .active {
  position: absolute;
  top: 0px;
  right: 28px;
  z-index: 2;
}

.notification_btn .active i {
  font-size: 8px;
  color: rgb(180, 2, 2);
}

.notification_btn .btn {
  width: 43px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #FFF;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 5px;
  margin-right: 20px;
}

.notification_btn .btn i {
  font-size: 24px;
}

.Accountdetails {
  display: flex;
  align-items: center;
  width: 230px;
  position: relative;
}

.Accountdetails .profile_pic {
  width: 42px;
  min-width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.Accountdetails .profile_pic img {
  width: 100%;
  height: 100%;
}

.Accountdetails .namearea .dropdown a {
  text-decoration: none;
  display: block;
  position: relative;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
}

.Accountdetails .namearea .dropdown a strong {
  display: block;
}

.Accountdetails .namearea .dropdown a span {
  display: block;
  opacity: 0.75;
}

.Accountdetails .namearea .dropdown-toggle::after {
  display: inline-block;
  margin-left: 15px;
  vertical-align: 0.255em;
  content: "\f078";
  border: none;
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translate(-50%, -50%);
}

.Accountdetails .namearea .dropdown-menu {
  box-shadow: 0px 0px 5px 0px #ccc;
}

.Accountdetails .namearea .dropdown-menu a {
  color: #2C3659;
  font-weight: 500;
}

.Accountdetails .namearea .dropdown-menu a i {
  opacity: 0.7;
}

/* 
=============
Dashboard
=============
*/

.main_wrap {
  width: calc(100% - 310px);
  height: auto;
  margin-left: auto;
  margin-top: 80px;
  padding: 0px 20px 30px;
}

/*----=== login  ===----*/

.login-main-div {
  height: 100vh;
}

.pass_input {
  position: relative;
}

.eye_icon {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
}










/* project */


/**recentlybidded_sec**/
.middlepartwrap {
  width: 100%;
  /* height: 150vh;
  overflow-y: auto; */
}

.job_dtls {
  padding: 30px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.job_dtls .projImg img {
  height: 300px;
  width: 100%;
}

.setting_botton {
  margin-bottom: 20px;
  width: 47%;

}

.setting_botton a {
  background: rgba(6, 109, 230, 0.10);
  color: #066DE6;
  font-size: 17px;
  font-weight: 500;
  border-radius: 10px;
  width: 100%;
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.setting_botton a:hover {
  border: 1px solid #066DE6;
  background-color: #066DE6;
  color: #fff;
  text-decoration: none;
}

/* .middlepartwrap::-webkit-scrollbar {
  display: none;
} */

.recentlybidded_sec {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.recentlybidded_sec .allcard_wrap {
  width: 100%;
  height: auto;
}

.recentlybidded_sec .card_box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
  padding: 20px 15px 10px 30px;
  margin-bottom: 20px;
}

body.drakmode .recentlybidded_sec .card_box {
  background: #066DE6;
}

.recentlybidded_sec .card_box .top_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recentlybidded_sec .card_box .top_ .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.recentlybidded_sec .card_box .action_fun {
  display: flex;
  align-items: center;
}

.Active_btn {
  border-radius: 4.471px;
  background: #066DE6;
  display: flex;
  width: 95px;
  height: 33px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  text-decoration: none;
  box-shadow: none;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fffffb;
  text-align: center;
  margin-right: 10px;
}

.Moredot_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0.75;
  cursor: pointer;
}

.recentlybidded_sec .card_box .text {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 156.5%;
  padding: 0;
  margin: 0 0 10px 0;
  width: 100%;
  max-width: 480px;
  opacity: 0.75;
}


.recentlybidded_sec .card_box .bottom_ {
  display: flex;
  align-items: center;
}

.Attachment_btn {
  border-radius: 5.429px;
  background: #d6d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
  margin-right: 15px;
}



/* 
=============
Responsive
=============
*/

@media (max-width: 1400px) {

  .sidebarwrap {
    width: 280px;
  }

  .mainheader_sec {
    width: calc(100% - 280px);
  }

  .main_wrap {
    width: calc(100% - 280px);
    padding: 0px 20px 30px;
  }

  .logo_area {
    min-width: 170px;
    height: 60px;
  }

}

@media (max-width: 1199px) {
  .mainheader_sec .rightcontent {
    width: 55%;
  }

  .Search_box .form-inline {
    width: 280px;
  }

  .activityprogress .boxwrap {
    height: 120px;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 10px;
    top: 32%;
  }
}

@media (max-width: 991px) {
  .sidebarwrap {
    width: 280px;
    /* position: absolute; */
    top: 0;
    left: -300px;
    height: 100%;
  }

  .sidebarwrap.show {
    left: 0px;
  }

  #backbtnsidebar {
    display: flex;
  }

  .mainheader_sec {
    width: 100%;
  }

  .main_wrap {
    width: 100%;
  }

  .responsive_menu {
    display: block;
  }
}


.posted_icon {
  color: #000;
  font-family: 'Plus Jakarta Sans';
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  text-decoration: none;
}


/* single project */

/**recentlybidded_sec**/
.middlepartwrap {
  width: 100%;
  /* height: 150vh;
  overflow-y: auto; */
}

.job_dtls {
  padding: 30px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.job_dtls .projImg img {
  height: 300px;
  width: 100%;
}

.setting_botton {
  margin-bottom: 20px;
  width: 47%;

}

.setting_botton a {
  background: rgba(6, 109, 230, 0.10);
  color: #066DE6;
  font-size: 17px;
  font-weight: 500;
  border-radius: 10px;
  width: 100%;
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.setting_botton a:hover {
  border: 1px solid #066DE6;
  background-color: #066DE6;
  color: #fff;
  text-decoration: none;
}

/* .middlepartwrap::-webkit-scrollbar {
  display: none;
} */

.recentlybidded_sec {
  width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.recentlybidded_sec .allcard_wrap {
  width: 100%;
  height: auto;
}

.recentlybidded_sec .card_box {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 4px 120px 0px rgba(169, 173, 180, 0.15);
  padding: 20px 15px 10px 30px;
  margin-bottom: 20px;
}

body.drakmode .recentlybidded_sec .card_box {
  background: #066DE6;
}

.recentlybidded_sec .card_box .top_ {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recentlybidded_sec .card_box .top_ .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.recentlybidded_sec .card_box .action_fun {
  display: flex;
  align-items: center;
}

.Active_btn {
  border-radius: 4.471px;
  background: #066DE6;
  display: flex;
  width: 95px;
  height: 33px;
  padding: 5px 16px;
  justify-content: center;
  align-items: center;
  border: none;
  outline: 0;
  text-decoration: none;
  box-shadow: none;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #fffffb;
  text-align: center;
  margin-right: 10px;
}

.Moredot_btn {
  text-decoration: none;
  box-shadow: none;
  outline: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  opacity: 0.75;
  cursor: pointer;
}

.recentlybidded_sec .card_box .text {
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: 156.5%;
  padding: 0;
  margin: 0 0 10px 0;
  width: 100%;
  max-width: 480px;
  opacity: 0.75;
}


.recentlybidded_sec .card_box .bottom_ {
  display: flex;
  align-items: center;
}

.Attachment_btn {
  border-radius: 5.429px;
  background: #d6d5d5;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 8px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  opacity: 0.75;
  margin-right: 15px;
}

/* 
=============
rightsidebar
=============
*/
.credit_history {
  padding: 50px;
}

.credit_history table button {
  border: 0;
  background: #8ce387;
  font-size: 14px;
  color: #fff;
  padding: 4px 15px;
  border-radius: 15px;
}

.credit_history .top {
  border: 0;
  background: #2C3659;
  font-size: 14px;
  color: #fff;
  padding: 4px 15px;
  border-radius: 15px;
}

.credit_history .top:hover {
  text-decoration: none;
}

.credit_history button:focus {
  outline: 0;
}

.credit_history table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  overflow-x: auto;
  white-space: nowrap;
  margin-top: 30px;
}

.credit_history h4 {
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 900;
}

.wallet_mine.for_mine button {
  margin: 0 auto;
}

.wallet_mine.for_mine {
  width: 350px;
  height: 274px;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.credit_history td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.credit_history .wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.my_wallet {
  cursor: pointer;
}






.credit_history tr:nth-child(even) {
  background-color: #dddddd9e;
}

.rightsidebar {
  width: 100%;
  height: auto;
  padding: 30px;
  background-color: #fff;
}

.wallet_bg {
  background-color: rgb(0 0 0 / 29%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2%;
  position: fixed;
  z-index: 999;
}

.wallet_mine {
  width: 350px;
  height: 274px;
  position: relative;
  margin: 0 auto;
  background-color: #fff;
  border-radius: 20px;
  padding: 40px 20px;
}

.wallet_mine.account {
  height: 374px;
}

.wallet_mine a {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
}

.credit_history .wrap h5 {
  font-size: 25px;
}

.wallet_mine a:hover {
  color: #000;
}

.wallet_mine button {
  background: #8ad38a;
  border: 0;
  padding: 6px 24px;
  border-radius: 7px;
  color: #fff;
  margin-left: auto;
  display: table;
  font-weight: bold;
}

button:focus {
  outline: 0;
}

.wrapForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 84%;
  align-items: normal;
}

body.drakmode .rightsidebar {
  background-color: #000;
}

.paymentDetail_Card {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 35px;
}

.paymentDetail_Card .headtag {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
}

.paymentDetail_Card .headtag h4 {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: #F5F5F5;
  padding: 0;
  margin-bottom: 5px;
}

.paymentDetail_Card .headtag p {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 9px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.paymentDetail_Card .amout h2 {
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  padding: 0;
  margin-bottom: 10px;
}

.ViewDetails_btn .btn {
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.42);
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #FFF;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
  box-shadow: none;
  outline: 0;
  text-decoration: none;
  cursor: pointer;
}

/**Supplier_Card**/
.Supplier_Card {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.Supplier_Card .topsec {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Supplier_Card .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.Supplier_Card .Seemore_btn .btn {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin: 0;
  opacity: 0.75;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  color: rgba(44, 54, 89, 0.50);
}

body.drakmode .Supplier_Card .Seemore_btn .btn {
  color: #fff;
  opacity: 0.75;
}

.profilecardbox {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}

.profilecardbox .left {
  display: flex;
  align-items: center;
}

.profilecardbox .profilepic {
  width: 42px;
  height: 42px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 15px;
}

.profilecardbox .profilepic img {
  width: 100%;
  height: 100%;
}

.profilecardbox .name {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin-bottom: 5px;
}

.profilecardbox .text {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  padding: 0;
  margin: 0;
  opacity: 0.75;
}


.img-container {
  position: relative;
  bottom: 20;
  width: 70px;
  height: 70px;
  object-fit: contain;
  /* You can use 'contain', 'fill', or other values here */
}

.loadingBar {
  background-color: rgba(110, 109, 109, 0.58);
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 20;
  bottom: 0;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 210px
}


.spantext {
  color: #000;
  font-size: 12px;

}

.Viewprofile_btn {


  font-size: 1.1rem;
  padding: 17px 21px;

  border-radius: 5px;
  background: rgba(6, 109, 230, 0.10);
  height: 29px;

  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  color: #066DE6;
  font-family: Plus Jakarta Sans;
  /* font-size: 12px; */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.Viewprofile_btn:hover {
  color: #066DE6;
}

/**Lenders_card**/
.Lenders_card {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}

.Lenders_card .topsec {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.Lenders_card .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.Lenders_card .Seemore_btn .btn {
  font-family: Plus Jakarta Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin: 0;
  opacity: 0.75;
  text-decoration: none;
  outline: 0;
  box-shadow: none;
  color: rgba(44, 54, 89, 0.50);
}

body.drakmode .Lenders_card .Seemore_btn .btn {
  color: #fff;
  opacity: 0.75;
}

/* 
=============
rightsidebar
=============
*/

.Projectpage_area {
  width: 100%;
  height: auto;
  padding: 30px 0;
}

.Projectpage_area .toppart {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}

.Projectpage_area .toppart .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding: 0;
  margin: 0;
}

.Projectpage_area .actionpart {
  display: flex;
  align-items: center;
  list-style: none;
  margin-bottom: 0;
}

/* .Projectpage_area .actionpart .nav-item {
 
} */

.Projectpage_area .actionpart .nav-link {
  border-radius: 8px;
  border: 1.5px solid #E5E6E7;
  padding: 7px 10px;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0 15px 0 0;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
}

.Projectpage_area .actionpart .nav-link span {
  opacity: 0.75;
}

/***/

.Projecttrello_area {
  width: 100%;
  height: auto;
  padding: 20px 0;
  display: flex;
  align-items: start;
  overflow-x: auto;
}

.Projecttrello_area .cardbox {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  height: auto;
  margin-right: 30px;
}

.Projecttrello_area .cardhead {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 10px 15px;
  background: #EFF2F3;
  border-radius: 6px;
}

.Projecttrello_area .cardhead .name h4 {
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.75;
}

.Projecttrello_area .cardhead .cardcount p {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin: 0;
}

.CreateTask_btn .btn {
  border-radius: 10px;
  background: #E2E4EA;
  width: 100%;
  max-width: 300px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  outline: 0;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
}

.CompletedTask_btn {
  border-radius: 10px;
  background: #351188;
  width: 100%;
  max-width: 300px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  outline: 0;
  text-decoration: none;
  color: #f9f8fa;
  box-shadow: none;
  cursor: default;
  /* cursor: pointer; */
}


/*****/

.ShowSubtaskCard {
  width: 100%;
  height: auto;
  border-radius: 10px;
  background: #FFF;
  box-shadow: 0px 0px 5px 0px #ccc;
  margin: 10px 0;
  padding: 15px 15px;
}


.ShowSubtaskCard .content_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ShowSubtaskCard .content_wrap .left {
  margin-right: 15px;
}

.ShowSubtaskCard .content_wrap .head h4 {
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  padding: 0;
  margin-bottom: 17px;
}


.ShowSubtaskCard .content_wrap .text_ p {
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  padding: 0;
  margin: 0 15px 0 0;
  opacity: 0.75;
}

.ShowSubtaskCard .content_wrap .Edit_btn {
  border-radius: 4px;
  background: #E9E9E9;
  display: flex;
  width: 59px;
  height: 22px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  outline: 0;
  box-shadow: none;
  text-decoration: none;
  cursor: pointer;
  border: none;
}


.Markascompleted_btn {
  border-radius: 6px;
  background: #C9E1F7;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  color: #066DE6;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%;
  text-decoration: none;
  cursor: pointer;
  outline: 0;
  box-shadow: none;
  border: none;
  white-space: nowrap;
}

.headTagLink:hover {
  text-decoration: none;
  color: #2C3659;
}


/****noti_modal***/

/* 
=============
Responsive
=============
*/

@media (max-width: 1400px) {

  .sidebarwrap {
    width: 280px;
  }

  .mainheader_sec {
    width: calc(100% - 280px);
  }

  .main_wrap {
    width: calc(100% - 280px);
    padding: 0px 0px 30px 20px
  }

  .logo_area {
    min-width: 170px;
    height: 60px;
  }

  .Accountdetails .namearea .dropdown a {
    font-size: 14px;
  }

  .Accountdetails {
    width: 195px;
  }

  .Accountdetails .namearea .dropdown a span {
    font-size: 10px;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: -30px;
  }

  .activityprogress .boxwrap {
    height: 140px;
  }

  .Createpost_btn .btn {
    height: 40px;
    font-size: 13px;
    width: 153px;
    margin-right: 15px;
    white-space: nowrap;
    margin-left: 10px;
  }

  .profilecardbox .text {
    font-size: 10px;
  }

  .Viewprofile_btn {
    padding: 10px 10px;
    font-size: 10px;
  }

  .rightsidebar {
    padding: 25px 20px;
  }

  .paymentDetail_Card .amout h2 {
    font-size: 30px;
  }

  .ViewDetails_btn .btn {
    padding: 5px 10px;
    font-size: 11px;
  }

  .Supplier_Card .Seemore_btn .btn {
    font-size: 14px;
  }

  .Lenders_card .Seemore_btn .btn {
    font-size: 14px;
  }
}

@media (max-width: 1199px) {
  .mainheader_sec .rightcontent {
    width: 55%;
  }

  .Search_box .form-inline {
    width: 280px;
  }

  .activityprogress .boxwrap {
    height: 120px;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 10px;
    top: 32%;
  }
}

@media (max-width: 991px) {
  .sidebarwrap {
    width: 280px;
    /* position: absolute; */
    top: 0;
    left: -300px;
    height: 100%;
  }

  .sidebarwrap.show {
    left: 0px;
  }

  #backbtnsidebar {
    display: flex;
  }

  .mainheader_sec {
    width: 100%;
  }

  .main_wrap {
    width: 100%;
  }

  .responsive_menu {
    display: block;
  }
}

@media (max-width: 767px) {
  .credit_history {
    padding: 20px;
  }

  .credit_history .wrap {
    flex-direction: column;
  }

  .my_wallet {
    margin-left: auto;
    display: table;
  }

  .Accountdetails .namearea .dropdown a strong {
    display: none;
  }

  .Accountdetails .namearea .dropdown a span {
    display: none;
  }

  .Accountdetails {
    width: 42px;
    position: relative;
  }

  .Accountdetails .profile_pic {
    margin-right: 0;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 0;
    opacity: 0;
  }

  .mainheader_sec .rightcontent {
    width: 38%;
  }



  .Search_box {
    display: none;
  }

  .responsSearch_btn {
    display: block;
  }

  .mainheader_sec {
    height: 80px;
    padding: 10px 20px;
  }

  .main_wrap {
    margin-top: 70px;
  }

  .Projectpage_area .toppart {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .Projectpage_area .toppart .head h4 {
    margin-bottom: 15px;
  }

}

@media (max-width: 600px) {
  .mainheader_sec .rightcontent {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .mainheader_sec .rightcontent {
    width: 65%;
  }

  .main_wrap {
    padding: 0px 0px 30px 0px;
  }

  .activityprogress .boxwrap {
    height: 75px;
  }

  .welcomeJerremy_sec .headline h2 {
    font-size: 18px;
  }

  .welcomeJerremy_sec .headline .text {
    font-size: 12px;
  }

  .responsive_menu {
    font-size: 22px;
  }

  .responsSearch_btn {
    font-size: 14px;
    padding: 5px 10px;
  }

  .Createpost_btn .btn {
    height: 30px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .notification_btn .btn {
    width: 30px;
    height: 30px;
  }

  .notification_btn .btn i {
    font-size: 16px;
  }

  .notification_btn .active {
    top: -6px;
    right: 25px;
  }

  .Accountdetails {
    width: 32px;
  }

  .Accountdetails .profile_pic {
    width: 32px;
    height: 32px;
  }

  .head_tag .name h4 {
    font-size: 18px;
  }

  .welcomeJerremy_sec .headline {
    margin-bottom: 10px;
  }

  #backbtnsidebar {
    height: 40px;
  }

  .logo_area {
    height: 40px;
    min-width: 140px;
  }

  .logo_area .dropdown a {
    font-size: 14px;
  }

  .sidebarwrap {
    width: 250px;
  }

  .country-code {
    display: flex;
  }

  .account-form {
    position: relative;
    z-index: 1;
    background: #FFFFFF;
    /* max-width: 398px; */
    width: 500px;
    margin: 0 auto 100px;
    padding: 45px;
    text-align: center;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
  }

}

@media (max-width:380px) {
  .wallet_mine {
    width: 100%;
  }

  .wallet_bg {
    justify-content: inherit;
  }
}

/* 
=============
Create Project
=============
*/

.rmsc .dropdown-heading .dropdown-heading-value {
  width: 100%;
  max-width: 480px;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}





.create-project-main {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 9999;
  background-color: #0000008f;
}

.create-project {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: auto;
  width: 100%;
  max-width: 580px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 30px;
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 20px 20px 50px 10px pink inset;
}

.create-project form {
  width: 100%;
  height: 500px;
  overflow-y: auto;
  overflow-x: hidden;
}

/* .create-project form::-webkit-scrollbar {
  display: none;
} */

.create-project .closebtn {
  position: absolute;
  top: 15px;
  right: 15px;
  z-index: 2;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: 0;

}

.create-project .closebtn i {
  font-size: 24px;
  color: #000;
}

.add-project {
  display: flex;
  align-items: center;
  width: 100%;
  text-align: left;
  margin-bottom: 20px;
}

.add-project .arrow {
  font-size: 20px;
  cursor: pointer;
}

.add-project h3 {
  font-size: 26px;
  font-weight: 600;
  margin-top: 10px;
}

.description,
.address,
.post_code,
.spend_money,
.timeline,
.Attach_Image {
  margin-top: 25px;
  font-weight: bold;
}

.service_input,
.address_input {
  border-radius: 8px;
}

.service_input option {
  width: 20px;
}

.icon_marge {
  position: relative;
}

.icon_marge input {
  padding: 5px 45px 5px 10px;
  width: 100%;
  max-width: 600px;
  height: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.location_icon_div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 28px;
  width: 28px;
  border: 1px solid rgb(177 172 172);
  border-radius: 4px;
  margin: 0;
  cursor: pointer;
}

.location_icon_div .location_icon {
  font-size: 16px;
  color: red;
}

.spend_money h5,
.timeline h5,
.Attach_Image h5 {
  font-weight: bold;
  font-size: 17px;
}

.submit_btn {
  margin-top: 15px;
}

.sterlingmainwrap {
  position: relative;
}

.sterlingmainwrap input {
  padding: 5px 5px 5px 28px;
}

.sterlingicon {
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  z-index: 1;
  background-color: transparent;
  width: 20px;
  height: auto;
}

.sterlingicon i {
  font-size: 16px;
  color: #000;
}

.Attach_Image_box {
  width: 100%;
  max-width: 300px;
  min-width: 300px;
  height: auto;
  border: 1px dashed #6e6e6e;
  border-radius: 4px;
  padding: 20px 30px;
  position: relative;

}

.Attach_Image_box input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.Attach_Image_box .cnt_box {
  width: 100%;
  height: auto;
}

.Attach_Image_box .cnt_box .icon {
  font-size: 28px;
  text-align: center;
}

.Attach_Image_box .cnt_box .text {
  font-size: 16px;
  text-align: center;
}

.add_product_img {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 110px;
  border: 1px solid #ccc;
  margin: auto 5px 0;
  border-radius: 4px;
}

.add_product_img .closebtn {
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: 0;
  z-index: 2;
  width: 18px;
  height: 15px;
  color: red;
  margin: 0.1rem;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  padding: 0;
}

.add_product_img img {
  width: 100%;
  height: 100%;
}


/*****BidViewProjectDetails****/

.BidViewProjectDetails {
  width: 100%;
  height: auto;
  padding: 25px 0;
}

.BidViewProjectDetails .head_line h4 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 130%;
  padding: 0;
  margin-bottom: 20px;
}

.BidViewProjectDetails .projectImg_wrap {
  width: 100%;
  height: auto;
}


.BidViewProjectDetails .projImg {
  width: 100%;
  height: 300px;
  border-radius: 12px;
  overflow: hidden;
}

.BidViewProjectDetails .projImg img {
  width: 100%;
  height: 100%;
}

.BidViewProjectDetails .projectName h4 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin-bottom: 14px;
}


.BidViewProjectDetails .bookmark_icon {
  width: 20px;
  height: 20px;
  font-size: 20px;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: none;
  outline: 0;
  cursor: pointer;
  text-align: center;
  color: #000;
  text-decoration: none;
  box-shadow: none;
}

.ProjectPostedBy {
  width: 100%;
  height: auto;
}

.ProjectPostedBy .heding {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin-bottom: 15px;
}

.ProjectPostedBy .profile {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ProjectPostedBy .profPic {
  width: 37px;
  height: 37px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 12px;
}

.ProjectPostedBy .profPic img {
  width: 100%;
  height: 100%;
}

.ProjectPostedBy .details h5 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.165px;
  padding: 0;
  margin: 0 10px 0 0;
}

.ProjectPostedBy .details p {
  color: #666;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.165px;
  padding: 0;
  margin: 0;
}

.ProjectPostedBy .details p span {
  color: #FF6D00;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.165px;
}

.ProjectPostedBy .details h6 {
  color: #A7AEC1;
  font-family: Plus Jakarta Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.165px;
  padding: 0;
  margin: 0;
}

.ProjectPostedBy .ProjectDescription h4 {
  color: #000;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 0;
  margin-bottom: 5px;
}

.ProjectPostedBy .ProjectDescription p {
  color: #666;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 171.5%;
  padding: 0;
  margin-bottom: 14px;
}

.ProjectPostedBy .ProjectDescription ul {
  list-style: disc;
  margin-left: 20px;
}


.ProjectPostedBy .ProjectDescription ul li {
  color: #666;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
}

.ProjectPostedBy .attachment_wrap {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.ProjectPostedBy .attachment_wrap .attacImg {
  width: 90px;
  height: 90px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin-right: 15px;
}

.ProjectPostedBy .attachment_wrap .attacImg img {
  width: 100%;
  height: 100%;
}

.ProjectPostedBy .attachment_wrap .downloadbtn {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000054;
  z-index: 2;
  cursor: pointer;
}

.ProjectPostedBy .attachment_wrap .downloadbtn i {
  color: #fff;
}

.ProjectPostedBy .Seemore_btn {
  color: #066DE6;
  font-family: Plus Jakarta Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  box-shadow: none;
  text-decoration: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  background-color: transparent;
}

.ProjectPostedBy .profile_wrap_main {
  width: 100%;
  height: auto;
  margin: 10px 5px;
}

.ProjectPostedBy .profile_wrap_main .text p {
  color: #666;
  font-family: Plus Jakarta Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 151.5%;
  letter-spacing: -0.165px;
}





@media (max-width: 767px) {
  .Accountdetails .namearea .dropdown a strong {
    display: none;
  }

  .Accountdetails .namearea .dropdown a span {
    display: none;
  }

  .Accountdetails {
    width: 42px;
    position: relative;
  }

  .Accountdetails .profile_pic {
    margin-right: 0;
  }

  .Accountdetails .namearea .dropdown-toggle::after {
    right: 0;
    opacity: 0;
  }

  .mainheader_sec .rightcontent {
    width: 38%;
  }

  .Createpost_btn .btn {
    height: 36px;
    font-size: 10px;
    width: 100px;
    margin-right: 10px;
  }

  .Search_box {
    display: none;
  }

  .responsSearch_btn {
    display: block;
  }

  .mainheader_sec {
    height: 80px;
    padding: 10px 20px;
  }

  .main_wrap {
    margin-top: 70px;
  }



}

@media (max-width: 600px) {
  .mainheader_sec .rightcontent {
    width: 50%;
  }
}

@media (max-width: 480px) {
  .mainheader_sec .rightcontent {
    width: 65%;
  }

  .main_wrap {
    padding: 0px 10px 30px;
  }

  .activityprogress .boxwrap {
    height: 75px;
  }

  .notification_btn .btn {
    width: 30px;
    height: 30px;
  }

  .notification_btn .btn i {
    font-size: 16px;
  }

  .notification_btn .active {
    top: -6px;
    right: 25px;
  }

  .Accountdetails {
    width: 32px;
  }

  .logo_area {
    height: 40px;
    min-width: 140px;
  }

  .sidebarwrap {
    width: 250px;
  }

}

.form-header {
  width: 78%;
  padding: 4px 6px;
  margin-top: 89px;
}

.datatable-view {
  padding-left: 321px;
  padding-right: 10px;
}

.form-page-headline {
  text-align: "center";
  font-size: "30px";
  color: "#868e96";
  margin: "35px";
  font-weight: "bold"
}

/* button */
.btn-grad {
  background-image: linear-gradient(to right, #314755 0%, #26a0da 51%, #314755 100%);
  /* margin: 10px; */
  /* padding: 15px 45px; */
  text-align: center;
  transition: 0.9s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.btn-grad-cancel {
  background-image: linear-gradient(to right, #ce0202 0%, #ff0101 51%, #fdfeff 100%);
  /* margin: 10px; */
  /* padding: 15px 45px; */
  text-align: center;
  transition: 0.9s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #eee;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin-left: 1px;
}


.btn-grad:hover {
  background-position: right center;
  /* change the direction of the change here */
  color: #fff;
  background-size: 400%;
  animation: glowing 20s linear infinite;
  text-decoration: none;
}

.ReactModal__Overlay {
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 2%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background-color: rgb(67 65 65 / 75%) !important;
  z-index: 999;

}

.ReactModal__Content {
  width: 800px;
  height: 500px;
  background-color: #fff;
  border-radius: 10px;
  margin: 0 auto;
  overflow-y: auto;
  inset: auto !important;
  position: relative !important;
}

.mtclose {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 25px;
}

/* Eye button for modal */
.icon-shake:hover {
  animation: shake 0.5s;
  font-size: 25px;
}

/*  */
.tooltipItem {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltipItem .tooltipItemName {
  visibility: hidden;
  width: 200px; 
  height: 100px;
  overflow: auto;
  background-color: #ffffff;
  border: 3px solid #0000004b;
  color: #000;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  /* bottom: 1%; Position above the tooltip */
  top: 1%;
  left: 60%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltipItem:hover .tooltipItemName {
  visibility: visible;
  opacity: 1;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }

  100% {
    transform: translateX(0);
  }
}